// Pre.tsx
import React, { useState, useRef } from 'react'
import { IconCopy, IconCheck } from '@tabler/icons-react'

// Extend from HTML <pre> attributes so we can spread them onto the <pre> element
interface PreProps extends React.HTMLAttributes<HTMLPreElement> {
  children?: React.ReactNode
}

const Pre: React.FC<PreProps> = ({ children, ...rest }) => {
  const textInput = useRef<HTMLDivElement>(null)
  const [hovered, setHovered] = useState(false)
  const [copied, setCopied] = useState(false)

  const onEnter = () => setHovered(true)
  const onExit = () => {
    setHovered(false)
    setCopied(false)
  }
  const onCopy = () => {
    if (textInput.current) {
      const textContent = textInput.current.textContent ?? ''
      navigator.clipboard.writeText(textContent)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }

  return (
    <div
      ref={textInput}
      onMouseEnter={onEnter}
      onMouseLeave={onExit}
      className="relative group"
    >
      {/* COPY BUTTON */}
      <button
        onClick={onCopy}
        aria-label="Copy code"
        className={`
          absolute top-2 right-2
          inline-flex items-center justify-center
          rounded-md p-1.5 text-sm
          bg-gray-700 text-gray-200
          border border-transparent
          transition-all duration-150
          opacity-0 group-hover:opacity-100
          hover:bg-gray-600 focus:bg-gray-600
        `}
      >
        {copied ? (
          <IconCheck className="w-5 h-5 text-green-400" />
        ) : (
          <IconCopy className="w-5 h-5" />
        )}
      </button>

      {/* ACTUAL <pre> BLOCK WITH PRISM CLASSES, ETC. */}
      <pre
        {...rest}
        className={`
          ${rest.className || ''}
          bg-gray-800 text-gray-200
          rounded-lg p-3
          overflow-auto
        `}
      >
        {children}
      </pre>
    </div>
  )
}

export default Pre
