import { db } from "../config/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { format } from "date-fns";

const saveActivity = async (userId: string, activityType: string) => {
  const now = new Date();
  const todayString = format(now, "yyyy-MM-dd"); // e.g. "2024-03-15"
  const currentMonth = format(now, "yyyy-MM");
  const docRef = doc(db, "activity", userId, "monthlyActivity", currentMonth);

  try {
    const docSnap = await getDoc(docRef);
    // compute a full timestamp with local or UTC
    const fullTimestamp = now.toISOString();

    if (docSnap.exists()) {
      const data = docSnap.data();
      const currentDayData = data[todayString] || {
        addCount: 0,
        submitCount: 0,
        lastUpdatedAt: null
      };

      currentDayData[activityType] = (currentDayData[activityType] || 0) + 1;
      // Update lastUpdatedAt every time we record activity
      currentDayData.lastUpdatedAt = fullTimestamp;

      await setDoc(docRef, { [todayString]: currentDayData }, { merge: true });
    } else {
      await setDoc(docRef, {
        [todayString]: {
          [activityType]: 1,
          addCount: activityType === "addCount" ? 1 : 0,
          submitCount: activityType === "submitCount" ? 1 : 0,
          lastUpdatedAt: fullTimestamp
        }
      });
    }
  } catch (error) {
    console.error("Error saving activity:", error);
  }
};

export default saveActivity;
