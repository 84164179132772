import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import OpenAI from 'openai';
import { TextField, Button, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { ArrowUpCircleIcon, StopCircleIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { IconMessage, IconEdit, IconX } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { auth } from "./config/firebase";
import { User } from "firebase/auth";
import { IconMaximize, IconMinimize, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import loadingGif from './assets/loading.gif'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import rehypeHighlight from 'rehype-highlight';
import 'highlight.js/styles/atom-one-dark.css';
import 'prismjs/themes/prism-okaidia.css';
import rehypePrism from 'rehype-prism-plus'
import { db } from './config/firebase';
import { doc, getDoc, updateDoc, setDoc, getDocs, increment, collection, query, where, addDoc } from 'firebase/firestore';
import { planModelLimits } from './modelLimits';
import Pre from './copy';

interface ChatUIProps {
  messages: any[];
  setMessages: React.Dispatch<React.SetStateAction<any[]>>;
  codeContent: string;
  TextContent: string;
  answerType: string;
  leetCodeQuestion: string;
  leetCodeAnswer: string;
  questionType: string;
  additionalPrompt: string;
  isChatMaximized: boolean;
  isChatFolded: boolean;
  setIsChatMaximized: React.Dispatch<React.SetStateAction<boolean>>;
  minimizeChatPane: () => void;
  unminimizeChatPane: () => void;
}

const ChatUI: React.FC<ChatUIProps> = ({
  messages,
  setMessages,
  codeContent,
  TextContent,
  answerType,
  leetCodeQuestion,
  leetCodeAnswer,
  questionType,
  additionalPrompt,
  isChatMaximized,
  isChatFolded,
  setIsChatMaximized,
  minimizeChatPane,
  unminimizeChatPane
}) => {

  const [inputText, setInputText] = useState<string>("");
  const [tempPrompt, setTempPrompt] = useState<string>('');
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const [promptDialogOpen, setPromptDialogOpen] = useState(false);

  // For general errors displayed by a MUI Snackbar.
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  // For all your custom error dialogs (API key or other problems).
  // Instead of "apiKeyDialogOpen", let's make it more general:
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [startedMessage, setStartedMessage] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  // Was previously "apiKeyDialogOpen", we now rely on the more general states above:
  // const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);

  const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive');
  const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({});
  const [plan, setPlan] = useState('');

  // A separate dialog just for usage-limit-exceeded (or you can combine this too, if desired).
  const [limitDialogOpen, setLimitDialogOpen] = useState(false);
  const [exceededModel, setExceededModel] = useState<string | null>(null);
  const [currentUsage, setCurrentUsage] = useState<number>(0);
  const [modelLimit, setModelLimit] = useState<number>(0);
  const [isActive, setIsActive] = useState(false); // Track if either section is active
  const containerRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the container
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsActive(false); // Disable both borders if clicked outside
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
      const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

      const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
      const subs: any[] = [];

      subscriptionSnap.forEach((doc) => {
        subs.push(doc.data());
      });

      if (subs.length > 0) {
        const activeSubscription = subs[0];
        if (activeSubscription.cancel_at_period_end) {
          setCancelAtNextPeriod(true);
        }

        console.log(activeSubscription.items[0]);
        setSubscriptionStatus(activeSubscription.status);
        const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None';
        setPlan(planNickname);
        setHasAccess(true);
        return;
      } else {
        setPlan('None');
      }

      // 2. Check for free trial eligibility (7 days after sign-up)
      const signUpDate = new Date(currentUser.metadata.creationTime!);
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - signUpDate.getTime();
      const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

      // If within 7-day trial period
      if (daysSinceSignUp < 7) {
        setTrialRemaining(7 - daysSinceSignUp);
        console.log("User has a free trial");
        setHasAccess(true);
        return;
      }

      setHasAccess(false);
      setSubscriptionStatus('Inactive');
    };

    fetchSubscriptions();
  }, [user]);

  const fetchUsageStats = async (authUser: User) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
    const userId = authUser.uid;

    // Reference to the userUsage document for the current month
    const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

    try {
      const userUsageSnap = await getDoc(userUsageRef);
      if (userUsageSnap.exists()) {
        const data = userUsageSnap.data();
        setUsageStats(data.models || {});
      } else {
        setUsageStats({});
      }
    } catch (error) {
      console.error('Error fetching usage stats:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        fetchUsageStats(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (loading) {
      interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev.length < 5) {
            return prev + '.';
          }
          return '';
        });
      }, 250);
    } else {
      setLoadingText('');
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  const handleClosePromptDialog = () => {
    setTempPrompt('');
    setPromptDialogOpen(false);
  };

  const handleInputTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const preprocessLaTeX = (content: string) => {
    // Replace block-level LaTeX delimiters \[ \] with $$ $$
    const blockProcessedContent = content.replace(
      /\\\[(.*?)\\\]/gs,
      (_, equation) => `$$${equation}$$`,
    );
    // Replace inline LaTeX delimiters \( \) with $ $
    const inlineProcessedContent = blockProcessedContent.replace(
      /\\\((.*?)\\\)/gs,
      (_, equation) => `$${equation}$`,
    );
    return inlineProcessedContent;
  };

  const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
    return (
      <ReactMarkdown
        children={preprocessLaTeX(markdownContent)}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex, rehypePrism]}
        components={{
          pre: (props) => <Pre {...props} />
        }}
      />
    );
  };

  const RenderHTMLContent: React.FC<{ content: string }> = ({ content }) => {
    return <div className="custom-html-content" dangerouslySetInnerHTML={{ __html: content }} />;
  };

  function stripHtmlTagsWithDOMParser(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }

  const handleSendMessage = async () => {
    const userId = user?.uid || '';
    const localApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
    const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
    const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

    // Decide which API key to use:
    const apiKey = (plan === 'Advanced' || plan === 'Standard' || plan === 'None')
      ? process.env.REACT_APP_OPENAI_API_KEY
      : localApiKey;

    // 1) Check if the user’s own key is not activated when required.
    if (!isApiKeyActivated && !(plan === 'Advanced' || plan === 'Standard' || plan === 'None')) {
      console.error('API key is not activated.');
      setErrorDialogTitle('OpenAI API Key Error');
      setErrorMessage('It looks like the OpenAI API key has not been activated. Please set and activate your key in the navbar.');
      setErrorDialogOpen(true);
      return;
    }

    // 2) Check if the user does not have any key at all
    if (!apiKey) {
      console.error('API key is not available.');
      setErrorDialogTitle('Missing API Key');
      setErrorMessage('No API key was found. Please add or activate your OpenAI key before proceeding.');
      setErrorDialogOpen(true);
      return;
    }

    // 3) Check if the user forgot to choose a model
    if (!latestModelName) {
      console.error('Model not recognized.');
      setErrorDialogTitle('Model Error');
      setErrorMessage('Currently selected model not recognized. Please pick a valid ChatGPT model in the navbar.');
      setErrorDialogOpen(true);
      return;
    }

    // 4) Check subscription status
    if (!hasAccess) {
      console.error('7-day free trial has expired');
      setErrorDialogTitle('Subscription Required');
      setErrorMessage('Your 7-day free trial has expired. Please upgrade your plan to continue using HinterviewGPT.');
      setErrorDialogOpen(true);
      return;
    }

    // 5) Check usage limits for the chosen model
    const planLimits = planModelLimits[plan]; // Access limits for the user's plan
    const modelLimit = planLimits?.[latestModelName] || 0;
    if (!modelLimit) {
      console.error('Model limit not found for the current plan.');
      // You could decide to show a generic error or do something else
      setErrorDialogTitle('Model Limit Error');
      setErrorMessage('Model limit not found for this plan. Please select a different plan or model.');
      setErrorDialogOpen(true);
      return;
    }

    // 6) Check usage so far
    const userUsage = usageStats[latestModelName] || 0;

    // We do the final usage check after re-fetching usageStats inside the request logic, but if you want to do an immediate check:
    if (userUsage >= modelLimit) {
      console.error('API request limit reached for the current month.');
      setExceededModel(latestModelName);
      setCurrentUsage(userUsage);
      setModelLimit(modelLimit);
      setLimitDialogOpen(true); // Or you could unify with the same errorDialog if you prefer
      return;
    }

    // If all checks pass, proceed with normal message logic
    try {
      const userId = auth.currentUser?.uid;
      if (inputText.trim() !== "" && userId && user) {
        // Double check usage again after fetch:
        await fetchUsageStats(user);

        // Possibly re-check if usage was updated:
        const updatedUsage = usageStats[latestModelName] || 0;
        if (updatedUsage >= modelLimit) {
          console.error('API request limit reached for the current month.');
          setExceededModel(latestModelName);
          setCurrentUsage(updatedUsage);
          setModelLimit(modelLimit);
          setLimitDialogOpen(true);
          return;
        }

        setLoading(true);
        setStartedMessage(true);

        const contentToSave = answerType === 'Code' ? codeContent : TextContent;
        const userMessage = inputText;
        const updatedMessages =
          messages.length === 2
            ? [
              ...messages,
              { role: 'system', content: `Question: ${leetCodeQuestion}` },
              additionalPrompt ? { role: 'system', content: additionalPrompt } : null,
              { role: 'system', content: `User Solution: ${contentToSave}` },
              { role: 'user', content: userMessage },
            ].filter(Boolean)
            : [
              ...messages,
              { role: 'system', content: `Code Content: ${contentToSave}` },
              { role: 'user', content: userMessage },
            ];

        setMessages(updatedMessages);
        setInputText("");

        const openai = new OpenAI({
          apiKey: apiKey,
          dangerouslyAllowBrowser: true
        });

        // Firestore usage doc for the current month
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        // Fetch current usage doc
        let requestCount = 0;
        try {
          const userUsageSnap = await getDoc(userUsageRef);
          if (userUsageSnap.exists()) {
            const data = userUsageSnap.data();
            requestCount = data.models[latestModelName] || 0;
            console.log('Current usage:', requestCount);
          }
        } catch (error) {
          console.error('Error fetching user usage data:', error);
          setErrorMessage('Could not fetch user usage data.');
          setErrorSnackbarOpen(true);
          setLoading(false);
          return;
        }

        let assistantResponse = '';
        const chatCompletion = await openai.chat.completions.create({
          messages: updatedMessages,
          model: latestModelName,
          stream: true
        });

        let iterationCount = 0;
        for await (const part of chatCompletion) {
          setStartedMessage(false);
          assistantResponse += part.choices[0]?.delta?.content || '';
          if (iterationCount === 0) {
            setMessages((prev) => [
              ...prev,
              { role: 'assistant', content: assistantResponse },
            ]);
          } else {
            setMessages((prev) => [
              ...prev.slice(0, -1),
              { role: 'assistant', content: assistantResponse },
            ]);
          }
          iterationCount++;
        }

        // Update usage doc
        try {
          await updateDoc(userUsageRef, {
            [`models.${latestModelName}`]: increment(1)
          });
        } catch (error: any) {
          // If the document does not exist, create it
          if (error.code === 'not-found') {
            await setDoc(userUsageRef, {
              year,
              month,
              models: {
                [latestModelName]: 1
              }
            });
          } else {
            console.error("Error updating user usage:", error);
          }
        }

        setLoading(false);
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
      }
    } catch (error: any) {
      console.error('Error in handleSendMessage:', error);
      setLoading(false);
      // For unexpected errors, we can just show the MUI Snackbar:
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'An error occurred while sending the message. Please try again.'
      );
      setErrorSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleClearConversation = () => {
    // Clear all messages except the first two
    setMessages([
      messages[0],
      messages[1],
    ]);
  };

  const handleMaximizeClick = () => {
    setIsChatMaximized(true);
  };

  const handleMinimizeClick = () => {
    setIsChatMaximized(false);
  };

  return (
    <div
    ref={containerRef}

      className="chat-ui bg-gray-200"
      onClick={() => setIsActive(true)}

      style={{
        paddingTop: '4px', paddingRight: '7px', paddingLeft: '4px', paddingBottom: '2px',
        
      }}
    >
      {/* ======== Chat Header ======== */}
      <div

        style={{
          display: 'flex', alignItems: 'center', paddingBottom: '2px', paddingTop: '3px',
          backgroundColor: '#fafafa', position: 'sticky', top: '0',
          borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
          borderTop: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
          borderLeft: `1px solid ${isActive ? '#dadada' : 'transparent'}`, 
          borderRight: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
        }}
      >
        <div
          className="font-medium text-sm"
          style={{
            display: 'flex', alignItems: 'center',
            fontWeight: 'bold', fontSize: '15px'
          }}
        >
          <IconMessage
            className="mr-1 ml-2 mt-1 mb-1 inline-block text-blue-500"
            style={{ width: '20px', height: '20px' }}
          />
          HinterviewGPT Chat
        </div>

        <div
          className="cursor-pointer text-gray-500 hover:text-gray-900"
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '0px' }}
        >
          {/* New Chat button */}
          <div
            className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-2"
            onClick={handleClearConversation}
          >
            <Tooltip title="New Chat">
              <IconEdit className=" w-5 h-5 text-gray-500" />
            </Tooltip>
          </div>

          {!isChatMaximized && (
            <>
              {isChatFolded ? (
                <div
                  className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
                  onClick={unminimizeChatPane}
                >
                  <Tooltip title="Unfold">
                    <IconChevronDown className="w-5 h-5 text-gray-500 " />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
                  onClick={minimizeChatPane}
                >
                  <Tooltip title="Fold">
                    <IconChevronUp className="w-5 h-5 text-gray-500 " />
                  </Tooltip>
                </div>
              )}
            </>
          )}

          {/* Maximize / Minimize */}
          {!isChatMaximized && (
            <div
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
              onClick={handleMaximizeClick}
            >
              <Tooltip title="Maximize">
                <IconMaximize className="w-5 h-5 text-gray-500" />
              </Tooltip>
            </div>
          )}
          {isChatMaximized && (
            <div
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1"
              onClick={handleMinimizeClick}
            >
              <Tooltip title="Minimize">
                <IconMinimize className="w-5 h-5 text-gray-500 " />
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      {/* ======== Messages ======== */}
      <div
        className="messages"
        ref={messagesContainerRef}
        style={{ backgroundColor: 'white',borderLeft: `1px solid ${isActive ? '#dadada' : 'transparent'}`, 
          borderRight: `1px solid ${isActive ? '#dadada' : 'transparent'}`, }}
      >
        {messages.map((message, index) => {
          let messageClass = 'message';
          if (message.role === 'user') {
            messageClass += ' user';
          } else if (message.role === 'assistant') {
            messageClass += ' ai';
          }

          if (message.role !== 'system') {
            return (
              <div
                key={index}
                style={{
                  marginLeft: '0px', marginTop: '10px',
                  display: 'block',
                  paddingRight: isChatMaximized ? '150px' : '20px',
                  paddingLeft: isChatMaximized ? '150px' : '20px',
                }}
              >
                {message.role === 'assistant' && messageClass.includes('ai') && (
                  <div
                    className="ai-message-2"
                    style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0px' }}
                  >
                    <img
                      src={logo}
                      style={{
                        borderRadius: '50%',
                        border: '1px solid #dadada',
                        marginLeft: '2px',
                        marginRight: '10px',
                        marginBottom: '0px',
                        marginTop: '1px',
                        objectFit: 'cover',
                        width: isChatMaximized ? '35px' : '30px',
                        height: isChatMaximized ? '35px' : '30px',
                      }}
                    />
                    <div
                      className="ai-message-content-2"
                      style={{
                        display: 'block',
                        overflowX: message.content.includes('<pre>') ? 'auto' : 'hidden',
                        whiteSpace: message.content.includes('<pre>') ? 'pre' : 'normal',
                        wordWrap: 'break-word',
                        width: '100%',
                      }}
                    >
                      <MarkdownViewer markdownContent={message.content} />
                    </div>
                  </div>
                )}

                {message.role !== 'assistant' && (
                  <div
                    className="ai-message"
                    style={{
                      marginLeft: 'auto', marginRight: '0',
                      marginTop: '20px', marginBottom: '20px',
                      textAlign: 'right'
                    }}
                  >
                    <div
                      className="ai-message-content"
                      style={{
                        marginLeft: '40%',
                        marginBottom: '0px',
                        textAlign: 'left',
                        display: 'inline-block'
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          backgroundColor: '#f0f0f0',
                          padding: '12px',
                          borderRadius: '40px',
                          marginRight: '10px'
                        }}
                      >
                        <div style={{ marginRight: '10px', marginLeft: '10px' }}>
                          <MarkdownViewer markdownContent={message.content} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}

        {startedMessage && (
          <div className="ai-message-2">
            <div
              className="ai-message-2"
              style={{
                display: 'flex', alignItems: 'flex-start',
                marginLeft: isChatMaximized ? '150px' : '20px'
              }}
            >
              <img
                src={logo}
                style={{
                  borderRadius: '50%',
                  border: '1px solid #dadada',
                  marginLeft: '2px',
                  marginRight: '10px',
                  marginBottom: '0px',
                  marginTop: '1px',
                  objectFit: 'cover',
                  width: isChatMaximized ? '35px' : '30px',
                  height: isChatMaximized ? '35px' : '30px',
                }}
              />
              {loadingText}
            </div>
          </div>
        )}
      </div>

      {/* ======== Input ======== */}
      <div
        className="w-full flex flex-col items-center justify-center bg-white"
        style={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderLeft: `1px solid ${isActive ? '#dadada' : 'transparent'}`, 
          borderRight: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
          borderBottom: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
        }}
      >
        <div
          className="relative w-11/12"
          style={{
            paddingRight: isChatMaximized ? '130px' : '30px',
            paddingLeft: isChatMaximized ? '130px' : '30px',
          }}
        >
          <input
            type="text"
            value={inputText}
            onChange={handleInputTextChange}
            placeholder="Message HinterviewGPT"
            onKeyPress={handleKeyPress}
            className="w-full h-12 p-6 rounded-full pr-14 mb-2"
            style={{ backgroundColor: '#f4f4f4', outline: 'none' }}
          />
          <button
            onClick={handleSendMessage}
            className="absolute right-8 top-1/2 transform -translate-y-1/2 p-1 focus:outline-none"
            disabled={loading}
          >
            <ArrowUpCircleIcon
              className={`h-10 w-10 text-gray-500 mb-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              style={{ marginRight: isChatMaximized ? '100px' : '0px' }}
            />
          </button>
        </div>
      </div>

      {/* ======== MUI Snackbar for unexpected errors ======== */}
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleErrorSnackbarClose}>
          {errorMessage.startsWith("400 This model's maximum context length") ? (
            <span>
              Maximum conversation length reached. Please start a new conversation to continue.
            </span>
          ) : (
            <span>{errorMessage}</span>
          )}
        </Alert>
      </Snackbar>

      {/* ======== A single "Error" Dialog for most errors ======== */}
      <Transition show={errorDialogOpen}>
        <Dialog
          className="relative z-10"
          onClose={() => setErrorDialogOpen(false)}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  {/* Close Button (X) */}
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setErrorDialogOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <IconX className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {/* Dialog Content */}
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                      >
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {errorDialogTitle || 'OpenAI API Key Error'}
                        </DialogTitle>
                        <div className="mt-2 mb-2">
                          <p className="text-sm text-gray-500">
                            {errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Footer */}
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <Button
                      onClick={() => setErrorDialogOpen(false)}
                      color="primary"
                      variant="contained"
                    >
                      OK
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* ======== Model Limit Dialog remains separate ======== */}
      <Transition show={limitDialogOpen}>
        <Dialog
          className="relative z-10"
          onClose={() => setLimitDialogOpen(false)}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Model Usage Limit Exceeded
                        </DialogTitle>
                        <div className="mt-2 mb-2">
                          <p className="text-sm text-gray-500">
                            You have reached your API request limit for the <strong>{exceededModel}</strong> model.
                            <br />
                            Current usage: <strong>{currentUsage}</strong> requests out of <strong>{modelLimit}</strong> for this month.
                            <br />
                            Please upgrade your plan or wait until the next billing cycle to continue using this model.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <Button
                      onClick={() => setLimitDialogOpen(false)}
                      color="primary"
                      variant="contained"
                    >
                      OK
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>
  );
};

export default ChatUI;
