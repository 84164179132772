import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { subMonths, format } from 'date-fns';

interface DayActivity {
  addCount: number;
  submitCount: number;
  /**
   * Optional: the full ISO timestamp of the last update for this day
   * (e.g. "2024-03-15T10:35:12.123Z")
   */
  lastUpdatedAt?: string;
}

/**
 * This interface maps a day-string (e.g. "2024-03-15")
 * to a DayActivity object with addCount, submitCount, and lastUpdatedAt
 */
interface MonthlyActivity {
  [dayString: string]: DayActivity;
}

/**
 * Fetch the last 12 months of activity from Firestore.
 * Each monthly doc has day-level keys (e.g. "2024-03-15") => { addCount, submitCount, lastUpdatedAt }
 */
const getLastYearActivity = async (userId: string): Promise<MonthlyActivity> => {
  try {
    const today = new Date();
    // Build array of last 12 months in "yyyy-MM"
    const pastYearMonths = Array.from({ length: 12 }, (_, i) => subMonths(today, i))
      .map(date => format(date, "yyyy-MM"));

    // We'll merge all day-level data from these 12 monthly docs
    const monthlyActivities: MonthlyActivity = {};

    const fetchMonthData = async (month: string) => {
      const docRef = doc(db, "activity", userId, "monthlyActivity", month);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // docSnap.data() should be an object like:
        // {
        //   "2024-03-15": {
        //     addCount: 3,
        //     submitCount: 1,
        //     lastUpdatedAt: "2024-03-15T10:35:12.123Z"
        //   },
        //   ...
        // }
        const data = docSnap.data() as MonthlyActivity;
        // Merge each day key => { addCount, submitCount, lastUpdatedAt } into monthlyActivities
        Object.assign(monthlyActivities, data);
      }
    };

    // Fetch all 12 monthly docs in parallel
    await Promise.all(pastYearMonths.map(month => fetchMonthData(month)));

    return monthlyActivities;
  } catch (error) {
    console.error("Error fetching activity data:", error);
    return {};
  }
};

export default getLastYearActivity;
