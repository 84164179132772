import { useEffect, useState } from 'react';
import { auth, db } from './config/firebase';
import { User } from "firebase/auth";
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { Firestore, collection, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { CheckIcon } from '@heroicons/react/20/solid'

interface Price {
    id: string;  // Add id to represent the price document ID
    currency: string;
    unit_amount: number;
    interval: 'day' | 'week' | 'month' | 'year';
}


interface Product {
    name: string;
    description: string;
    prices: Price[];
    features: string[];
}

const Plan = () => {
    const [user, setUser] = useState<User | null>(null);
    const [userSet, setUserSet] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);


    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const productsData: Product[] = [];
            for (const productDoc of querySnapshot.docs) {
                const productData = productDoc.data() as Omit<Product, 'prices'>;
                const pricesCollection = collection(productDoc.ref, 'prices');
                const pricesSnapshot = await getDocs(pricesCollection);
                const prices: Price[] = pricesSnapshot.docs.map((priceDoc) => {
                    const priceData = priceDoc.data() as Omit<Price, 'id'>;  // Exclude id
                    return {
                        id: priceDoc.id,  // Manually add the Firestore ID
                        ...priceData,     // Spread the rest of the price data
                    };
                });

                const features = productData.name === 'Bring your own key'
                    ? ['Requires OpenAI API key', 'Pay OpenAI directly for your usage', 'HinterviewGPT never stores your key', 'Access to GPT-4o and GPT-4o-mini', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                    : productData.name === 'Standard'
                        ? ['250 calls per month for GPT-4o model', '500 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                        : productData.name === 'Advanced'
                            ? ['500 calls per month for GPT-4o model', '750 calls per month for GPT-4o-mini model', 'Generate interview questions', 'Access to AI tutor', 'AI solution evaluation', 'Priority support', 'Cancel anytime']
                            : ['Basic feature 1', 'Basic feature 2'];

                productsData.push({ ...productData, prices, features });
            }
            setProducts(productsData);
        };

        fetchProducts();
    }, []);

    const handleCheckout = async (priceId: string) => {
        const user = auth.currentUser;

        if (!user) {
            // If the user isn't logged in, redirect them to the login page
            window.location.href = '/auth';
            return;
        }

        const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');

        try {
            const docRef = await addDoc(checkoutSessionRef, {
                price: priceId,
                success_url: `${window.location.origin}/success`, // Redirect to the success page after payment
                cancel_url: `${window.location.origin}/upgrade`,
            });

            // Listen for the session URL from Stripe and redirect the user
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data()!;
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url); // Redirect to Stripe Checkout
                }
            });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('An error occurred while creating the checkout session.');
        }
    };


    return (
        <div className="flex flex-col min-h-screen bg-white ">
            {/* Navbar */}
            <Navbar currentNav="Help" />
            <div id="pricing" className="scroll-mt-14 py-16 sm:py-20">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="text-center mx-auto max-w-3xl">
                        <h2 className="text-4xl font-extrabold text-gray-900">Upgrade your plan</h2>
                        <p className="mt-4 text-lg text-gray-600">
                            Whether you are casually preparing or in hardcore study mode for a big interview, HinterviewGPT has a plan for you.
                        </p>
                    </div>
                    <div className="mt-2 flow-root">
                        {products.length > 0 && (
                            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                {products
                                    .sort((a, b) => {
                                        const order = ["Bring Your Own Key", "Standard", "Advanced"];
                                        return order.indexOf(a.name) - order.indexOf(b.name);
                                    })
                                    .map((product, index) => (
                                        <div
                                            key={product.name}
                                            className={`ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10 relative ${index === 1 ? "ring-indigo-600 " : ""}`}
                                        >
                                            {index === 1 && (
                                                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                                    Most Popular
                                                </div>
                                            )}

                                            <div className="flex items-center justify-between gap-x-4">
                                                <h3 className="text-gray-900 text-lg font-semibold leading-8">
                                                    {product.name}
                                                </h3>
                                            </div>
                                            <p className="mt-4 text-sm leading-6 text-gray-600">
                                                {product.description}
                                            </p>
                                            <p className="mt-6 flex items-baseline gap-x-1">
                                                <span className="text-4xl font-bold tracking-tight text-gray-900">
                                                    ${product.prices[0].unit_amount / 100}
                                                </span>
                                                <span className="text-sm font-semibold leading-6 text-gray-600">
                                                    /month
                                                </span>
                                            </p>
                                            <a
                                                onClick={() => handleCheckout(product.prices[0].id)}
                                                className="
    cursor-pointer 
    w-full 
    bg-indigo-600 
    text-white 
    shadow-sm 
    hover:bg-indigo-500 
    mt-6 
    block 
    rounded-md 
    px-3 
    py-2 
    text-center 
    text-sm 
    font-semibold 
    leading-6 
    focus-visible:outline 
    focus-visible:outline-2 
    focus-visible:outline-offset-2 
    focus-visible:outline-indigo-600
  "
                                            >
                                                Get Started
                                            </a>


                                            <ul
                                                role="list"
                                                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                                            >
                                                {product.features.map((feature) => (
                                                    <li key={feature} className="flex gap-x-3">
                                                        <CheckIcon
                                                            aria-hidden="true"
                                                            className="h-6 w-5 flex-none text-indigo-600"
                                                        />
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Plan;
