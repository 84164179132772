import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { db } from './config/firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import MuiAlert from '@mui/material/Alert';
import OpenAI from 'openai';
import { generateQuestionPrompt } from './Prompts';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import yaml from 'js-yaml';
import Navbar from './navbar';
import 'katex/dist/katex.min.css';
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import './App.css'
import saveActivity from './utils/saveActivity';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Menu, MenuButton, MenuItem as HeadlessMenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { IconEdit, IconSparkles, IconTag, IconX } from '@tabler/icons-react';
import { doc, getDoc, updateDoc, setDoc, increment } from "firebase/firestore";
import { planModelLimits } from './modelLimits';
import { Button } from '@mui/material';


interface InterviewQuestion {
    title: string;
    tags: string[];
    answerType: string;
}

interface ParsedData {
    interview_question: InterviewQuestion;
}


type QuestionType = {
    qRef: string;
    title: string;
    tags: string[];
    difficulty: string;
    answerType: string;
};


const GenerateQuestion = () => {

    const [questionTitle, setQuestionTitle] = React.useState('');
    const [tags, setTags] = React.useState<string[]>([]);
    const [questionBody, setQuestionBody] = React.useState('');
    const [selectedAnswerType, setSelectedAnswerType] = React.useState('');
    const [genTags, setGenTags] = React.useState<string[]>([]);
    const [genQuestionBody, setGenQuestionBody] = React.useState('');
    const [genAnswerType, setGenAnswerType] = React.useState('');
    const [genQuestionTitle, setGenQuestionTitle] = React.useState('');
    const [genQuestionAnswer, setGenQuestionAnswer] = React.useState('');
    const [user, setUser] = useState<User | null>(null); // User state
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [questionPrompt, setQuestionPrompt] = useState('');
    const [isGenQuestionLoading, setIsGenQuestionLoading] = useState(false); // State to control loading spinner
    const [hasLoadedGenQuestion, setHasLoadedGenQuestion] = useState(false); // State to control loading spinner
    const [previousGeneration, setPreviousGeneration] = React.useState<[string, string][]>([]);
    // const [modelName, setModelName] = useState<string>(() => localStorage.getItem('hinterviewGpt_modelName') || 'gpt-3.5-turbo-0125');
    const [genCompany, setGenCompany] = React.useState('');
    const [genDifficulty, setGenDifficulty] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [genRole, setGenRole] = React.useState('');
    const [numQuestions, setNumQuestions] = React.useState(1);
    const [maxQuestionsDialogOpen, setMaxQuestionsDialogOpen] = React.useState(false);
    const [isLoadingQuestion, setIsLoadingQuestion] = React.useState(false);
    const [isLoadingTags, setIsLoadingTags] = React.useState(false);
    const [isLoadingSolution, setIsLoadingSolution] = React.useState(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive'); // Subscription status state
    const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({}); // State to store API usage stats
    const [plan, setPlan] = useState('');
    const [limitDialogOpen, setLimitDialogOpen] = useState(false);
    const [exceededModel, setExceededModel] = useState<string | null>(null);
    const [currentUsage, setCurrentUsage] = useState<number>(0);
    const [modelLimit, setModelLimit] = useState<number>(0);

    useEffect(() => {
        // Update form validation state whenever relevant state changes
        setIsFormValid(!!questionTitle && !!selectedAnswerType && !!questionBody && tags.length <= MAX_TAGS);
    }, [questionTitle, selectedAnswerType, questionBody, tags]);

    const MAX_TAGS = 5; // Maximum number of tags
    const MAX_QUESTION_BODY_LENGTH = 10000;

    const navigate = useNavigate();

    const fetchUsageStats = async (authUser: User) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
        const userId = authUser.uid;

        // Reference to the userUsage document for the current month
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        try {
            const userUsageSnap = await getDoc(userUsageRef);
            if (userUsageSnap.exists()) {
                const data = userUsageSnap.data();
                setUsageStats(data.models || {});
            } else {
                setUsageStats({});
            }
        } catch (error) {
            console.error('Error fetching usage stats:', error);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is signed in.
                setUser(authUser);
                fetchUsageStats(authUser);
            } else {
                // No user is signed in.
                setUser(null);
                navigate('/auth');

            }
        });

        return () => unsubscribe(); // Unsubscribe on unmount
    }, []);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;

            const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
            const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

            const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
            const subs: any[] = [];

            subscriptionSnap.forEach((doc) => {
                subs.push(doc.data());
            });

            if (subs.length > 0) {
                const activeSubscription = subs[0];
                if (activeSubscription.cancel_at_period_end) {
                    setCancelAtNextPeriod(true);
                }

                console.log(activeSubscription.items[0]);
                setSubscriptionStatus(activeSubscription.status);
                const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None'; // Fetch plan nickname
                setPlan(planNickname);
                setHasAccess(true);
                return;
            } else {
                setPlan('None');
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(currentUser.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                console.log("User has a free trial");
                setHasAccess(true);
                return;
            }

            setHasAccess(false);
            setSubscriptionStatus('Inactive');
        };

        fetchSubscriptions();
    }, [user]);


    // useEffect(() => {
    //     const checkAccess = async () => {
    //         if (!user) return;

    //         // 1. Check subscription status
    //         const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
    //         const activeSubscriptions = query(subscriptionsRef, where('status', 'in', ['active', 'trialing']));
    //         const subscriptionSnap = await getDocs(activeSubscriptions);

    //         // If an active or trialing subscription exists
    //         if (!subscriptionSnap.empty) {
    //             console.log("User has an active or trialing subscription");
    //             setHasAccess(true);
    //             return;
    //         }

    //         // 2. Check for free trial eligibility (7 days after sign-up)
    //         const signUpDate = new Date(user.metadata.creationTime!); // Firebase stores sign-up date
    //         const currentDate = new Date();
    //         const timeDiff = currentDate.getTime() - signUpDate.getTime();
    //         const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

    //         // If within 7-day trial period
    //         if (daysSinceSignUp < 7) {
    //             setTrialRemaining(7 - daysSinceSignUp);
    //             console.log("User has a free trial");
    //             setHasAccess(true);
    //             return;
    //         }
    //         setHasAccess(false);

    //         // If no active subscription and free trial expired, redirect to the no-access page
    //         console.log("User does not have access");
    //         navigate('/no-access');
    //     };

    //     if (user) {
    //         checkAccess();
    //     }
    // }, [user, navigate]);


    useEffect(() => {

        document.title = 'Generate Question | HinterviewGPT';

    }, []);


    const getLeetCodeQuestions = async () => {
        // console.log("getLeetCodeQuestions called!");
        // console.log("user: ", user);

        if (!user) {
            return;
        }

        if (user) {
            try {

                const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionsListDoc = await getDoc(userQuestionsListRef);

                if (userQuestionsListDoc.exists()) {
                    // Explicitly define the type for question_data
                    const userQuestionsList = userQuestionsListDoc.data().questions || [];

                    // Convert the 'question_data' field to an array of QuestionType
                    const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType) => {
                        const { title, difficulty, qRef, tags, answerType } = question;

                        return {
                            qRef,
                            title,
                            tags,
                            difficulty,
                            answerType,
                        };
                    });
                    setNumQuestions(queriedData.length);

                }

            } catch (err) {
                console.error('Error getting documents: ', err);
            }
        }
    };

    useEffect(() => {
        if (user) {
            // Only call getLeetCodeQuestions if the user has been set
            getLeetCodeQuestions();
            // console.log(originalData);
        }
    }, [user]);


    interface RenderHTMLContentProps {
        content: string;
    }

    const renderMath = (htmlContent: string) => {
        // Define a regex pattern to find math expressions within \[...\] or \(...\)
        const mathPattern = /\\\[(.*?)\\\]|\\\((.*?)\\\)/g;

        return htmlContent.replace(mathPattern, (match, displayMath, inlineMath) => {
            try {
                // Render the math expression using KaTeX
                const renderedMath = katex.renderToString(displayMath || inlineMath, {
                    throwOnError: false,
                    displayMode: !!displayMath, // Display mode for \[...\]
                });
                return renderedMath;
            } catch (error) {
                console.error("Failed to render math:", error);
                return match; // Return the original string if rendering fails
            }
        });
    };

    const RenderHTMLContent: React.FC<RenderHTMLContentProps> = ({ content }) => {
        // Sanitize the content and render math
        const sanitizedContent = DOMPurify.sanitize(renderMath(content));

        return (
            <div
                className="custom-html-content"
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
        );
    };


    const handleGenSubmit = async () => {

        if (user) {
            try {

                const userQuestionsCollectionRef = collection(db, 'userQuestions');

                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const currentDate = new Date();
                const options: Intl.DateTimeFormatOptions = { timeZone: userTimeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                const formattedDate = currentDate.toLocaleDateString(undefined, options);

                const newQuestionRef = await addDoc(userQuestionsCollectionRef, {
                    title: genQuestionTitle,
                    tags: genTags,
                    answerType: genAnswerType,
                    body: genQuestionBody,
                    answer: genQuestionAnswer,
                    user: user?.uid,
                    method: 'generated',
                    model: localStorage.getItem(`hinterviewGpt_modelName_${user.uid}`) || '',
                    createdAt: formattedDate
                });

                const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionsListDoc = await getDoc(userQuestionsListRef);

                if (userQuestionsListDoc.exists()) {
                    // Update existing document
                    await updateDoc(userQuestionsListRef, {
                        questions: [
                            ...userQuestionsListDoc.data().questions,
                            {
                                title: genQuestionTitle,
                                tags: genTags,
                                answerType: genAnswerType,
                                qRef: newQuestionRef.id
                            },
                        ],
                    });
                } else {
                    // Create new document
                    await setDoc(userQuestionsListRef, {
                        questions: [{
                            title: genQuestionTitle,
                            tags: genTags,
                            answerType: genAnswerType,
                            qRef: newQuestionRef.id
                        }],
                    });
                }

                const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${newQuestionRef.id}`);

                const userAttemptData = {
                    userID: user.uid,
                    questionID: newQuestionRef.id,
                    code: []
                };
                try {

                    await setDoc(userAttemptDocRef, userAttemptData);

                } catch (error) {
                    console.error('Error saving code:', error);
                    // Handle error, maybe show a notification to the user
                }

                setGenQuestionTitle('');
                setGenTags([]);
                setGenAnswerType('');
                setGenQuestionBody('');

                navigate(`/question/${newQuestionRef.id}`);
                await saveActivity(user.uid, "addCount");

            } catch (error) {
                console.error('Error adding document: ', error);
            }
        } else {
            console.error('User not authenticated.');
        }
    };


    const handleGenerateQuestion = async () => {
        if (!user) {
            console.error('User not authenticated.');
            setErrorMessage('User not authenticated.');
            return;
        }

        const userId = user.uid;
        const localApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
        const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
        const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

        const apiKey = (plan === 'Advanced' || plan === 'Standard' || plan === 'None')
            ? process.env.REACT_APP_OPENAI_API_KEY
            : localApiKey;

        if (!isApiKeyActivated && !(plan === 'Advanced' || plan === 'Standard' || plan === 'None')) {
            console.error('API key is not activated.');
            setErrorMessage('API key is not activated. ');
            return;
        }


        if (numQuestions > 182) {
            setMaxQuestionsDialogOpen(true);
            return;
        }

        if (!apiKey) {
            console.error('API key is not available.');
            setErrorMessage('API key is not available. ');
            return;
        }

        if (!latestModelName) {
            console.error('Model name not recognized.');
            setErrorMessage('Model name not recognized');
            return;
        }

        if (!hasAccess) {
            console.error("7 day trial has expired")
            setErrorMessage('7 day trial has expired, upgrade to continue using HinterviewGPT');
            return;
        }

        // Fetch the latest usage stats
        await fetchUsageStats(user);

        // Get the current usage for the selected model
        const userUsage = usageStats[latestModelName] || 0;

        // Fetch the limits for the user's plan and selected model
        const planLimits = planModelLimits[plan]; // Access limits for the user's plan
        const modelLimit = planLimits?.[latestModelName] || 0;

        if (!modelLimit) {
            console.error('Model limit not found for the current plan.');
            setErrorMessage('API request limit reached for the current month.');
            return;
        }

        // Check if the user's usage exceeds the allowed model limit
        if (userUsage >= modelLimit) {
            console.error('API request limit reached for the current month.');
            setErrorMessage('API request limit reached for the current month.');
            setExceededModel(latestModelName); // Set the model name
            setCurrentUsage(userUsage);         // Set the current usage
            setModelLimit(modelLimit);          // Set the model limit
            setLimitDialogOpen(true);
            return;
        }

        // Get the current date for usage tracking
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        // Check the current usage for the user
        let requestCount = 0;
        try {
            const userUsageSnap = await getDoc(userUsageRef);
            if (userUsageSnap.exists()) {
                const data = userUsageSnap.data();
                requestCount = data.models[latestModelName] || 0; // Get the current number of requests for the model
                console.log('Current usage:', requestCount);
            }

            // Optionally check for plan limits (if you want to enforce limits)
            // if (requestCount >= planLimitForModel) {
            //     setErrorMessage('API request limit reached for the current month.');
            //     return;
            // }
        } catch (error) {
            console.error('Error fetching user usage data:', error);
            setErrorMessage('Could not fetch user usage data.');
            return;
        }

        setHasLoadedGenQuestion(true);
        setIsGenQuestionLoading(true);
        setErrorMessage('');
        setGenAnswerType('');

        try {
            const openai = new OpenAI({
                apiKey: apiKey,
                dangerouslyAllowBrowser: true,
            });

            let questionGenPrompt = generateQuestionPrompt;

            setIsLoadingQuestion(true);
            if (previousGeneration.length !== 0) {
                const prevPromptResponses = previousGeneration.slice(-8).map(
                    ([prompt, response], index) => `Previous user description ${index + 1}: "${prompt}" - Response: "${response}"`
                ).join('\n');

                questionGenPrompt += `Do not generate questions that have already been shown for the same user description. Here are the previous user descriptions and corresponding generated question titles.\n${prevPromptResponses}.`;
            }

            if (genCompany.trim()) {
                questionGenPrompt += ` The user has requested that the interview question you generate is from the company ${genCompany}.`;
            }

            if (genRole.trim()) {
                questionGenPrompt += ` The user has requested that the interview question you generate is for the role of ${genRole}.`;
            }

            if (genDifficulty.trim()) {
                questionGenPrompt += ` The user has requested that the interview question you generate is of difficulty ${genDifficulty}.`;
            }

            const response = await openai.chat.completions.create({
                messages: [
                    { role: 'system', content: questionGenPrompt },
                    { role: 'user', content: `${questionPrompt}` },
                ],
                model: latestModelName,
                stream: false,
                temperature: 0.7,
            });

            const generatedQuestion = response.choices[0]?.message?.content || '{}';
            const strippedGeneratedQuestion = generatedQuestion.replace(/```html\s+|```/g, '');

            setGenQuestionBody(strippedGeneratedQuestion);

            setIsLoadingQuestion(false);
            setIsLoadingTags(true);

            const yamlResponse = await openai.chat.completions.create({
                messages: [
                    {
                        role: 'system', content: `You are an expert in creating job interview questions.
                        Given the following job interview question, generate a valid yaml string that includes a short but descriptive title (no more than 10 words) for the given question, a list of at most 3 informative tags for the question, and answerType which you will set to "Code" if the answer should be written in code or "Text" if the question should be written in text.  Your response should be a valid yaml string.  Here is an example of a yaml string you will generate, do not wrap it in \`\`\`yaml
                        
                        interview_question:
                           title: >
                               title of the question 
                           tags:
                               - tag 1
                               - tag 2
                               - tag 3
                           answerType: "Code" or "Text" 
   
                       Again, just return a yaml string, do not wrap it in \`\`\`yaml.
                       `
                    },
                    { role: 'system', content: `${strippedGeneratedQuestion}` }
                ],
                model: latestModelName,
                stream: false,
                temperature: 1.0,
            });



            const parsedData = yaml.load(yamlResponse.choices[0]?.message?.content || '{}') as ParsedData;

            setIsLoadingTags(false);
            setIsLoadingSolution(true);

            const questionAnswerResponse = await openai.chat.completions.create({
                messages: [
                    {
                        role: 'system', content: `You are an expert in solving job interview questions. Given the following job interview question, generate the correct answer to the question.  If the answer requires a diagram (for example, a system design diagram), draw it in plaintext. If there are multiple different correct solutions to this question generate at most 2 with the headings 'Solution #1' and 'Solution #2'.  Make sure the solution is correct and is written in an understandable way in detail."  
                       `
                    },
                    { role: 'system', content: `${strippedGeneratedQuestion}` }
                ],
                model: latestModelName,
                stream: false,
                temperature: 1.0,
            });

            const generatedAnswer = questionAnswerResponse.choices[0]?.message?.content || '{}';

            setIsLoadingSolution(false);

            setGenQuestionAnswer(generatedAnswer);
            setGenQuestionTitle(parsedData.interview_question.title);
            setGenTags(parsedData.interview_question.tags);
            setGenAnswerType(parsedData.interview_question.answerType);

            setPreviousGeneration((prev) => [...prev, [questionPrompt, parsedData.interview_question.title]]);

            // If the request was successful, update the number of API requests in Firebase
            try {
                await updateDoc(userUsageRef, {
                    [`models.${latestModelName}`]: increment(1)
                });
            } catch (error: any) {
                // If the document does not exist, create it with the initial request count
                if (error.code === 'not-found') {
                    await setDoc(userUsageRef, {
                        year,
                        month,
                        models: {
                            [latestModelName]: 1
                        }
                    });
                } else {
                    console.error("Error updating user usage:", error);
                }
            }


        } catch (error: any) {
            setHasLoadedGenQuestion(false);
            console.error('Error generating OpenAI text:', error.message);
            setErrorMessage(error.message);
        } finally {
            setIsGenQuestionLoading(false);
        }
    };





    return (

        <>
            <div className="flex flex-col min-h-screen">
                <Navbar currentNav="Add Question" />
                <header className="shadow-sm bg-white">
                    <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 flex items-center bg-white"> {/* Flex container to align items */}
                        <span className="text-sm font-semibold leading-6 text-gray-600 cursor-pointer" onClick={() => navigate('/add')}>Add Question</span> {/* Text before the title */}
                        <svg className="w-4 h-4 mx-2 font-semibold fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> {/* Right chevron icon */}
                            <path fillRule="evenodd" d="M7.293 15.293a1 1 0 0 1-1.414-1.414L10.586 10 5.293 4.707a1 1 0 1 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0z" />
                        </svg>
                        <h1 className="text-sm font-semibold leading-6 text-gray-600">Generate Interview Question</h1>
                    </div>
                </header>

                <div className="flex-grow bg-white overflow-hidden">

                    <div className=" mx-auto max-w-7xl py-2 px-4 sm:px-6 lg:px-8 mb-10">
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Generate Interview Question</h2>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {/* First card */}
                            <div className="divide-y border divide-gray-200 overflow-visible rounded-lg bg-white shadow" >
                                <div className="flex items-center justify-between px-4 py-2 sm:px-2" style={{ backgroundColor: '#f5f8fa' }}>
                                    <div className="flex items-center">
                                        <IconEdit className="h-5 w-5 mr-1 text-gray-600 " />
                                        <h3 className="text-md font-semibold leading-6 text-gray-900">Question Details</h3>
                                    </div>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <div>
                                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                            I want to practice a question about...
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                                defaultValue={''}
                                                placeholder='e.g. A software engineering interview question about tree traversal'
                                                onChange={(e) => setQuestionPrompt(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="account-number" className="block text-sm font-medium leading-6 text-gray-900">
                                            for role...
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm w-1/2">
                                            <input
                                                type="text"
                                                name="account-number"
                                                id="account-number"
                                                className="px-3  block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                placeholder="e.g. software engineer"
                                                onChange={(e) => setGenRole(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="account-number" className="block text-sm font-medium leading-6 text-gray-900">
                                            from company...
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm w-1/2">
                                            <input
                                                type="text"
                                                name="account-number"
                                                id="account-number"
                                                className="px-3  block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                placeholder="e.g. Google"
                                                onChange={(e) => setGenCompany(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="difficulty" className="block text-sm font-medium leading-6 text-gray-900">
                                            with difficulty...
                                        </label>
                                        <Menu as="div" className="relative inline-block w-full mt-1">
                                            <MenuButton className="inline-flex w-1/2 justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                                                {genDifficulty || 'None'}
                                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                                            </MenuButton>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <MenuItems className="absolute left-0 mt-2 w-40 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {['Easy', 'Medium', 'Hard'].map((level) => (
                                                            <HeadlessMenuItem key={level}>
                                                                {({ active }) => (
                                                                    <button
                                                                        onClick={() => setGenDifficulty(level)}
                                                                        className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                                            } block px-4 py-2 text-sm w-full text-left flex items-center justify-between`}
                                                                    >
                                                                        {level}
                                                                        {level === genDifficulty && (
                                                                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                                                        )}
                                                                    </button>
                                                                )}
                                                            </HeadlessMenuItem>
                                                        ))}
                                                    </div>
                                                </MenuItems>
                                            </Transition>
                                        </Menu>

                                    </div>


                                    <div className="mt-4">

                                        <button
                                            type="button"
                                            className="flex items-center rounded-md border border-gray-300 bg-gray-200 px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            onClick={handleGenerateQuestion}
                                        >
                                            Generate <span aria-hidden="true">&rarr;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Second card */}
                            <div className="divide-y border divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                                <div className="flex items-center justify-between px-4 py-2 sm:px-2" style={{ backgroundColor: '#f5f8fa' }}>
                                    <div className="flex items-center">
                                        <IconSparkles className="h-5 w-5 mr-1 text-gray-600" />
                                        <h3 className="text-md font-semibold leading-6 text-gray-900">Generated Question</h3>
                                    </div>
                                    {genAnswerType && (
                                        <div className="flex items-center">
                                            <span className="text-sm font-semibold leading-6 text-gray-600 mr-2">
                                                Answer Type:
                                            </span>
                                            <span className="rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                {genAnswerType}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="px-4 py-2 sm:p-6">
                                    {errorMessage && (
                                        <div className="mt-4">
                                            <MuiAlert severity="error">{errorMessage}</MuiAlert>
                                        </div>
                                    )}
                                    {!hasLoadedGenQuestion && !errorMessage && (
                                        <div className="relative block w-full rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <QuestionMarkCircleIcon />
                                            </svg>
                                            <span className="mt-2 block text-sm font-semibold text-gray-900">Generate question by filling out form on left</span>
                                        </div>
                                    )}
                                    {isGenQuestionLoading && !errorMessage && (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress />
                                            </Box>
                                            <div style={{ marginTop: '20px', fontSize: '16px', color: 'gray' }}>
                                                {isLoadingQuestion && "Step 1. Generating question..."}
                                                {isLoadingTags && "Step 2. Generating tags..."}
                                                {isLoadingSolution && "Step 3. Generating Solution..."}
                                            </div>
                                        </div>
                                    )}

                                    {!isGenQuestionLoading && !errorMessage && genQuestionBody && (
                                        <div className="divide-gray-200 overflow-hidden">
                                            <div className="">
                                                {/* Title Container */}
                                                <div className="flex items-center justify-between mb-2">
                                                    <div className="flex items-center">
                                                        <h2 className="text-2xl font-bold leading-7 text-gray-900 leading-">{genQuestionTitle}</h2>
                                                    </div>
                                                    <button onClick={handleGenSubmit}
                                                        className="flex items-center rounded-md border border-gray-300 bg-gray-200 px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                                        </svg>
                                                        Add
                                                    </button>
                                                </div>

                                                {/* Tags Container */}
                                                <div className="mb-4">
                                                    {genTags.map((tag, index) => (
                                                        <span key={index} className="inline-flex items-center rounded-xl bg-gray-400/10 px-2 py-1 text-xs font-medium mr-2 text-gray-500 ring-1 ring-inset ring-gray-400/20">
                                                            <IconTag className='w-3 h-3 mr-1' />{tag}
                                                        </span>
                                                    ))}
                                                </div>
                                                {/* Body Container */}
                                                <RenderHTMLContent content={genQuestionBody} />

                                            </div>
                                        </div>

                                    )}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <Transition show={maxQuestionsDialogOpen}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={() => setMaxQuestionsDialogOpen(false)}
                    >
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                                <Transition.Child
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => setMaxQuestionsDialogOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <IconX className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon
                                                        className="h-6 w-6 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-lg font-medium leading-6 text-gray-900"
                                                    >
                                                        Maximum Questions Reached
                                                    </Dialog.Title>
                                                    <div className="mt-2">
                                                        <p className="text-sm text-gray-500 mb-2">
                                                            You have reached the maximum number of questions. Please delete
                                                            a question from Question List if you want to add a new question.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>

                <div className="bg-white border-t border-gray-200 mt-auto">
                    <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                        <p className="text-gray-500">
                            © 2024 HinterviewGPT. All rights reserved.
                        </p>
                        <div>
                            <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                                Privacy Policy
                            </a>
                            <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                                Terms of Service
                            </a>
                        </div>
                    </div>
                </div>
                <Transition show={limitDialogOpen}>
                    <Dialog
                        className="relative z-10"
                        onClose={() => setLimitDialogOpen(false)}
                    >
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </TransitionChild>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <TransitionChild
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon
                                                        className="h-6 w-6 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <DialogTitle
                                                        as="h3"
                                                        className="text-base font-semibold leading-6 text-gray-900"
                                                    >
                                                        Model Usage Limit Exceeded
                                                    </DialogTitle>
                                                    <div className="mt-2 mb-2">
                                                        <p className="text-sm text-gray-500">
                                                            You have reached your API request limit for the <strong>{exceededModel}</strong> model.
                                                            <br />
                                                            Current usage: <strong>{currentUsage}</strong> requests out of a limit of <strong>{modelLimit}</strong> for this month.
                                                            <br />
                                                            Please upgrade your plan or wait until the next billing cycle to continue using this model.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                            <Button
                                                onClick={() => setLimitDialogOpen(false)}
                                                color="primary"
                                                variant="contained"
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>

        </>

    );
};

export default GenerateQuestion;
